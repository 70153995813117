export const defaultAbandonedCart = {
    // id: 1,
    name: 'AbandonedCart',
    cardTitle: 'Abandoned Cart Notifications',
    title: 'Abandoned cart recovery',
    description: 'Send multiple notifications to customers to remind them of abandoned cart',
    isActive: false,
    title1: 'Abandoned cart recovery',
    description1: 'Send multiple notifications to customers to remind them of abandoned cart',
    isActive1: false,
    title2: 'Abandoned cart recovery',
    description2: 'Send multiple notifications to customers to remind them of abandoned cart',
    isActive2: false,
    isEnabled: false,
  };

  export const defaultBackInStock = {
    // id: 2,
    name: 'BackInStock',
    title: 'Back In Stock',
    cardTitle: 'Back In Stock Notifications',
    description: 'Notify customers when a product they subscribe to is available to purchase again',
    isActive: false,
  };

  

  export const defaultPriceDrop = {
    // id: 3,
    name:'PriceDrop',
    title: 'Price Drop ',
    cardTitle: 'Price Drop Notifications',
    description: 'Notify customers when a product they subscribe to is having a discount to a certain price',
    isActive: false,
  };

  export const defaultWelcomeNotifications = {
    // id: 4,
    name: 'WelcomeNotifications',
    title : 'Welcome Notifications',
    cardTitle: 'Welcome Notifications',
    description: 'Welcome customers with a warm welcome message , welcome to shop we are here to help you',
    isActive: true,
  };
