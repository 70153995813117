import { useState, useEffect } from 'react';
import { Page, Layout, Card, Button, Image, Badge, HorizontalStack, Tabs, Toast, Modal } from '@shopify/polaris';
import { googleChrome } from "../assets";
import { EditMajor, DeleteMajor } from '@shopify/polaris-icons';
import StarRating from './rating';
// import { useAuthenticatedFetch } from '../hooks';

export default function HomePage() {

  const storeDomain = new URL(window.location).searchParams.get("shop");
  const [feedBackForm, setFeedBackForm] = useState(false);
  const [displayRatingSection, setDisplayRatingSection] = useState(true);
  const [sendNotifications, setSendNotifications] = useState([]);
  const [scheduledNotifications, setScheduledNotifications] = useState([]);
  const [notificationCount, setNotificationCount] = useState(0);
  const [notificationTokenCount, setNotificationTokenCount] = useState(0);
  const [notificationImpressionCount, setNotificationImpressionCount] = useState(0);
  const [selectedTab, setSelectedTab] = useState(0);
  const [toastMessage, setToastMessage] = useState('');
  const [showToast, setShowToast] = useState(false);
  const activeBadge = <Badge status="success">Active</Badge>;
  const expireBadge = <Badge status="critical">Expire</Badge>;
  const infinityBadge = <Badge status="success">Infinity</Badge>;
  // const fetch = useAuthenticatedFetch();

  const tabs = [
    {
      id: 'schedule',
      content: 'Schedule',
      accessibilityLabel: 'Scheduled Notifications',
      panelID: 'schedule-panel',
    },
    {
      id: 'send',
      content: 'Send',
      accessibilityLabel: 'Send Notifications',
      panelID: 'send-panel',
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('/api/get_data?shop='+storeDomain, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });
        if (response.ok) {
          const data = await response.json();
          if (data) {
            const { notification_token, notification_settings, notification_table } = data;
            if (notification_token && notification_token.length) {
                setNotificationTokenCount(notification_token.length);
              if(notification_settings.custom && notification_settings.custom.isRated === true){
                setDisplayRatingSection(false);
              }else {
                setDisplayRatingSection(true);
              }
            }
            if (notification_token && notification_token.length) {
              setNotificationTokenCount(notification_token.length);
            }
            if (notification_settings && notification_settings.custom && notification_settings.custom.push_registration_token_count) {
              setNotificationCount(notification_settings.custom.push_registration_token_count);
            }
            if (notification_settings && notification_settings.custom && notification_settings.custom.push_registration_impression_count) {
              setNotificationImpressionCount(notification_settings.custom.push_registration_impression_count);
            }

            const newSendNotifications = notification_table
              // .filter((notificationData) => notificationData.notification_payload.selectedOption === 'send')
              .map((notificationData) => {
                const { notification_payload } = notificationData;
                // const currentDate = new Date();
                const dateTime = new Date(notification_payload.selectedDate);
                const formattedDateTime = dateTime.toLocaleString();
                const statusBadge = (notificationData.notification_status === "inactive");
                return `${notificationData.id} - ${notification_payload.imageUrl} - ${notification_payload.notificationTitle} - ${notification_payload.notificationBody} - ${statusBadge} - ${formattedDateTime} - ${notification_payload.selectedOption}`;
            });

            setSendNotifications(newSendNotifications);
            
            const newNotifications = notification_table
              // .filter((notificationData) => notificationData.notification_payload.selectedOption !== 'send')
              .map((notificationData) => {
                const { notification_payload } = notificationData;
                const currentDate = new Date();
                const dateTime = new Date(notification_payload.selectedDate);
                const formattedDateTime = dateTime.toLocaleString();
                const statusBadge = (dateTime < currentDate && notificationData.notification_status === "inactive");
                return `${notificationData.id} - ${notification_payload.imageUrl} - ${notification_payload.notificationTitle} - ${notification_payload.notificationBody} - ${statusBadge} - ${formattedDateTime} - ${notification_payload.selectedOption}`;
            });

            setScheduledNotifications(newNotifications);
          }
        } else {
          console.error('Failed to fetch settings');
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [storeDomain]);

  const handleDelete = async (index) => {
    const updatedScheduledNotifications = [...scheduledNotifications];
    const deletedNotification = updatedScheduledNotifications.splice(index, 1)[0];
    setScheduledNotifications(updatedScheduledNotifications);
    const [id] = deletedNotification.split(' - ');
    console.log(`Deleted Row: ${[id]}`);

    try {
      const response = await fetch(`/api/delete_data?id=${id}&shop=${storeDomain}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        setToastMessage('Delete Settings successfully.');
      } else {
        setToastMessage('Failed to delete settings.');
      }
    } catch (error) {
      console.error('Error deleting data:', error);
    }
  };

  const [text, setText] = useState('');
  const [email, setEmail] = useState('');
  const [star, setStar] = useState('');
  const [resetStars, setResetStars] = useState(false);
  const handleTabChange = (selectedTabIndex) => {
    setSelectedTab(selectedTabIndex);
  };
  const handleFeedbackForm = (newRating) => {
    setStar(newRating);
    console.log(newRating);
    setFeedBackForm(true);
    setResetStars(false); 
  };
  const resetFeedbackStar = () => {
    setFeedBackForm(false);
    setResetStars(true); 
  };
  const MaxCharacterLimit = 500; 

  const handleTextChange = (event) => {
    const newText = event.target.value;
    if (newText.length <= MaxCharacterLimit) {
      setText(newText);
    }
  };
  const handleEmailChange = (event) => {
    const newEmail = event.target.value;
    setEmail(newEmail);
  };
  const filledCharacters = text.length;

  const handleSend = async () => {
    const contact = {
      email: email,
      message: text,
      star: star,
    };
    console.log(contact);
    const response = await fetch("/api/rating_feedback", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({contact}),
    });

    if (response.ok) {
      console.log(response.status);
    } else {
      console.error('Failed to save settings');
    }
    setFeedBackForm(false);
    setDisplayRatingSection(false);
  };

  const handleVisitStoreEmbed = async () => {
    const url = `https://${storeDomain}/admin/themes/current/editor?&context=apps&appEmbed=e2b926e3-be53-4cd5-96a2-19a2bd440040/elite_app_block&target=mainSection`;
    window.open(url, '_blank');
  };

  return (
    <Page 
      title="Campaign Dashboard"
      primaryAction={{
        content: 'Enable app on store',
        onAction: handleVisitStoreEmbed,
      }}
    >
      <Layout>
        <Layout.Section>
            
            {!displayRatingSection && (
              <Card title="Rate Our App" sectioned>
                <StarRating onFeedbackForm={handleFeedbackForm} resetStars={resetStars}></StarRating>
              </Card>
            )}
        </Layout.Section>
        <Layout.Section>
          <HorizontalStack gap="500" columns={3}>
            <Card title="Sent Campaign">
              Sent Campaign {notificationCount}
            </Card>
            <Card title="Subscribers">
              Subscribers {notificationTokenCount}
            </Card>
            <Card title="Impressions">
              Impressions {notificationImpressionCount}
            </Card>
          </HorizontalStack>
        </Layout.Section>
        <Layout.Section>
          <Card title="Campaign List">
              <Tabs tabs={tabs} selected={selectedTab} onSelect={handleTabChange}>
                {selectedTab === 1 && (
                <Card>
                {sendNotifications.length === 0 ? (
                  <p>No campaigns found.</p>
                ) : (
                  <table style={{ width: '100%' }}>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Image</th>
                        <th>Title</th>
                        <th>Body</th>
                        <th>Type</th>
                        <th>Date</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                    {sendNotifications.map((item, index) => {
                      const isSend = item.split(' - ')[6] === "send";
                      return isSend ? (
                        <tr key={index} style={{ background: index % 2 === 0 ? '#fff' : '#f5f5f5' }}>
                          <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>{index + 1}</td>
                          <td style={{ width: '20%', height: '30%', textAlign: 'center', verticalAlign: 'middle' }}>
                            <Image source={item.split(' - ')[1] !== '' ? item.split(' - ')[1] : googleChrome} style={{ width: '20%', height: '20%', objectFit: 'cover', display: 'block', margin: '0 auto' }} />
                          </td>
                          <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>{item.split(' - ')[2]}</td>
                          <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>{item.split(' - ')[3]}</td>
                          <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>{(item.split(' - ')[4] === "true") ? expireBadge : infinityBadge}</td>
                          <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>{item.split(' - ')[5]}</td>
                          <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                            <Button plain url={`/campaign?shop=${storeDomain}&edit=send&id=${index}`}><EditMajor style={{ width: '20px', marginRight: '20px' }}/></Button>
                            <Button plain onClick={() => handleDelete(index)}><DeleteMajor style={{ width: '20px' }}/></Button>
                          </td>
                        </tr>
                      ) : null;
                    })}
                    </tbody>
                  </table>
                )}
                </Card>
                )}
                {selectedTab === 0 && (
                <Card>
                {scheduledNotifications.length === 0 ? (
                  <p>No campaigns found.</p>
                ) : (
                  <table style={{ width: '100%' }}>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Image</th>
                        <th>Title</th>
                        <th>Body</th>
                        <th>Type</th>
                        <th>Date</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                    {scheduledNotifications.map((item, index) => {
                      const isSend = item.split(' - ')[6] === "schedule";
                      return isSend ? (
                        <tr key={index} style={{ background: index % 2 === 0 ? '#fff' : '#f5f5f5' }}>
                          <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>{index + 1}</td>
                          <td style={{ width: '20%', height: '30%', textAlign: 'center', verticalAlign: 'middle' }}>
                            <Image source={item.split(' - ')[1] !== '' ? item.split(' - ')[1] : googleChrome} style={{ width: '20%', height: '20%', objectFit: 'cover', display: 'block', margin: '0 auto' }} />
                          </td>
                          <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>{item.split(' - ')[2]}</td>
                          <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>{item.split(' - ')[3]}</td>
                          <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>{(item.split(' - ')[4] === "false") ? activeBadge : expireBadge}</td>
                          <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>{item.split(' - ')[5]}</td>
                          <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                            <Button plain url={`/campaign?shop=${storeDomain}&edit=schedule&id=${index}`}><EditMajor style={{ width: '20px', marginRight: '20px' }}/></Button>
                            <Button plain onClick={() => handleDelete(index)}><DeleteMajor style={{ width: '20px' }}/></Button>
                          </td>
                        </tr>
                      ) : null; // You can also return null for non-"send" items
                    })}
                    </tbody>
                  </table>
                )}
                </Card>
                )}
              </Tabs>
              <Button url={`/campaign?shop=${storeDomain}`}>Create New Campaign</Button>
          </Card>
        </Layout.Section>
      </Layout>
      <Page></Page>
      <Page></Page>
      <Page></Page>
      <Page></Page>
      {showToast && (
        <Toast content={toastMessage} onDismiss={() => setShowToast(false)} />
      )}
      <Modal
          open={feedBackForm}
          onClose={() => {
            setFeedBackForm(false);
            resetFeedbackStar(); 
          }}
          title="FeedBack Form"
          primaryAction={{
            content: 'Sumit',
            onAction: handleSend,
          }}
        >
          <Modal.Section>
          <h2 style={{ fontSize: '20px', marginBottom: '10px' }}>Enter Your Email</h2>
            <input
              type="email"
              name="email"
              value={email}
              onChange={handleEmailChange}
              placeholder="Enter your email..."
              style={{ width: '100%' , marginBottom: '10px'}}
            />
            <h2 style={{ fontSize: '20px', marginBottom: '10px'}}>Enter Your FeedBack</h2>
            <textarea
              name="feedback"
              value={text}
              onChange={handleTextChange}
              placeholder="Enter text here..."
              rows={7}
              cols={50}
              maxLength={350}
              style= {{ width : '100%'}}
            />
            <div>
              {filledCharacters}/{MaxCharacterLimit}
            </div>
          </Modal.Section>
        </Modal>
    </Page>
  );
}
