import React, { useState, useEffect } from 'react';
import { Card, Layout, Button, Page, Frame, TextContainer, HorizontalStack, Modal, Toast, TextField, Select } from '@shopify/polaris';
import { EditMajor } from '@shopify/polaris-icons';
import { defaultAbandonedCart, defaultBackInStock, defaultPriceDrop, defaultWelcomeNotifications } from './constants';

export default function PushNotificationSection() {

  const storeDomain = new URL(window.location).searchParams.get("shop");
  const [abandonedCart, setAbandonedCart] = useState(defaultAbandonedCart);
  const [backInStock, setBackInStock] = useState(defaultBackInStock);
  const [priceDrop, setPriceDrop] = useState(defaultPriceDrop);
  const [welcomeNotifications , setWelcomeNotifications] = useState(defaultWelcomeNotifications);
  const [showToast, setShowToast] = useState(false);
  const cardData = [ abandonedCart, backInStock ];
  const additionalCardData = [ welcomeNotifications,  priceDrop ];
  const [isEditing, setIsEditing] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [editFormData, setEditFormData] = useState({
    name: '',
    title: '',
    description: '',
    selectedTime: '5',
    isActive: null ,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('/api/get_data?shop='+storeDomain, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });
        if (response.ok) {
          const data = await response.json();
          if (data) {
            console.log(data);
            const automation = data.notification_settings.custom;
            setAbandonedCart(automation['AbandonedCart'] || defaultAbandonedCart);
            setBackInStock(automation['BackInStock'] || defaultBackInStock);
            setPriceDrop(automation['PriceDrop'] || defaultPriceDrop);
            setWelcomeNotifications(automation['WelcomeNotifications'] || defaultWelcomeNotifications);
          }
          console.log('Fetch settings successfully');
        } else {
          console.error('Failed to fetch settings');
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [storeDomain]);

  const handleEdit = (name) => {
    const cardToEdit = [...cardData, ...additionalCardData].find((card) => card.name === name);
    setEditFormData({ ...cardToEdit });
    setIsEditing(true);
  };
  const handleStatus = async (name) => {
    const cardToEdit = [...cardData, ...additionalCardData].find((card) => card.name === name);
    if (cardToEdit.name === 'AbandonedCart'){
      if(cardToEdit.isEnabled === true){
        cardToEdit.isEnabled = false;
        cardToEdit.isActive = false;
        cardToEdit.isActive1 = false;
        cardToEdit.isActive2 = false;
      }else {
        cardToEdit.isActive = true;
        cardToEdit.isActive1 = true;
        cardToEdit.isActive2 = true;
        cardToEdit.isEnabled = true;
      }
    }else {
      if(cardToEdit.isActive){
      cardToEdit.isActive = false;
      }else{
        cardToEdit.isActive = true;
      }
    }
    const custom = cardToEdit;
    const response = await fetch('/api/update_data?shop='+storeDomain, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ custom }),
    });

    if (response.ok) {
      setShowToast(true);
      setToastMessage('Settings saved successfully.');
    } else {
      setShowToast(true);
      setToastMessage('Failed to save settings.');  
    }
    setIsEditing(false);
  };

  const handleFormSubmit = async () => {

    const custom = editFormData;
    console.log('Custom', custom);
    const response = await fetch('/api/update_data?shop='+storeDomain, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ custom }),
    });

    if (response.ok) {
      setToastMessage('Settings saved successfully.');
    } else {
      setToastMessage('Failed to save settings.');  
    }
    setShowToast(true);
    setIsEditing(false);
  };

  return (
    <div style={{ height: '500px' }}>
      <Frame>
        <Page title="Push Notification Configuration">
          <Layout>
            <Layout.Section>
              <HorizontalStack spacing="extraTight" distribution="fill" wrap={false}>
                {cardData.map((card) => (
                  <div style={{ maxWidth : '430px', maxHeight: '350px', margin: '10px'}}>
                    <Card key={card.id}>
                      <TextContainer>
                        <h1><strong>{card.cardTitle}</strong></h1>
                        <h3><strong>Title:</strong> {card.title}</h3>
                        <p><strong>Message:</strong> {card.description}</p>
                      </TextContainer>
                    <div style={{ display: 'flex', justifyContent: 'space-between'}}>
                      <div>
                        <h3>Impressions</h3>
                        <text>{card.count || 0}</text>
                      </div>
                      <div style ={{ marginRight: '120px'}}>
                        <h3>Clicks</h3>
                        <text>0</text>
                      </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '5px'}}>
                      <div>
                        <h3>Avg.CTR</h3>
                        <text>0%</text>
                      </div>
                      <div style ={{ marginRight: '30px'}}>
                        <h3>Generated Revenue</h3>
                        <text>₹0.00</text>
                      </div>
                    </div>
                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div>
                          {card.name === 'AbandonedCart' ? (
                                (card.isActive || card.isActive1 || card.isActive2) ? (
                                  <span style={{ color: 'green' }}>Active</span>
                                ) : (
                                  <span style={{ color: 'red' }}>Inactive</span>
                                )
                              ) : (
                                card.isActive ? (
                                  <span style={{ color: 'green' }}>Active</span>
                                ) : (
                                  <span style={{ color: 'red' }}>Inactive</span>
                                )
                              )
                          }
                        </div>
                        <div style={{ display: 'flex', marginLeft: '150px', overflow: 'hidden' }}>
                          <Button  onClick={() => handleEdit(card.name)} >
                            <div style={{ display: 'flex'}}>
                              <EditMajor style={{ width: '15px', marginRight: '8px' }}/>
                              <span>Edit</span>
                            </div>
                          </Button>
                        <div style={{ marginLeft: '10px' }}>
                          <Button primary={!card.isActive} destructive={card.isActive} onClick={() => handleStatus(card.name)}  >
                          {card.name === 'AbandonedCart' ? (
                              (card.isActive || card.isActive1 || card.isActive2) ? (
                                <span>Disable</span>
                              ) : (
                                <span>Enable</span>
                              )
                            ) : (
                              card.isActive ? (
                                <span>Disable</span>
                              ) : (
                                <span>Enable</span>
                              )
                            )
                        }
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Card>
                  </div>
                ))}
              </HorizontalStack>
            </Layout.Section>
            <Layout.Section>
              <HorizontalStack spacing="extraTight" distribution="fill" wrap={false}>
                {additionalCardData.map((card) => (
                  <div style={{ maxWidth : '430px', maxHeight: '350px', margin: '10px'}}>
                    <Card key={card.id}>
                      <TextContainer>
                      <h1><strong>{card.cardTitle}</strong></h1>
                      <h3><strong>Title:</strong> {card.title}</h3>
                      <p><strong>Message:</strong> {card.description}</p>
                      </TextContainer>
                    <div style={{ display: 'flex', justifyContent: 'space-between'}}>
                      <div>
                        <h3>Impressions</h3>
                        <text>0</text>
                      </div>
                      <div style ={{ marginRight: '120px'}}>
                        <h3>Clicks</h3>
                        <text>0</text>
                      </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '5px'}}>
                      <div>
                        <h3>Avg.CTR</h3>
                        <text>0%</text>
                      </div>
                      <div style ={{ marginRight: '30px'}}>
                        <h3>Generated Revenue</h3>
                        <text>₹0.00</text>
                      </div>
                    </div>
                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div>
                          {card.isActive ? (
                            <span style={{ color: 'green' }}>Active</span>
                          ) : (
                            <span style={{ color: 'red' }}>Inactive</span>
                          )}
                        </div>
                        <div style={{display: 'flex', marginLeft: '150px', overflow: 'hidden'}}>
                        <Button  onClick={() => handleEdit(card.name)} >
                        <div style={{ display: 'flex' }}>
                        <EditMajor style={{ width: '15px', marginRight: '8px' }}/>
                          <span>Edit</span>
                        </div>
                        </Button>
                        
                        <div style={{ marginLeft: '10px' }}>
                        <Button primary={!card.isActive} destructive={card.isActive} onClick={() => handleStatus(card.name)}>
                            {card.isActive ? (
                                <span >Disable</span>
                              ) : (
                                <span >Enable</span>
                              )}
                        </Button>
                        </div>
                        </div>
                      </div>
                  </Card>
                  </div>
                ))}
              </HorizontalStack>
            </Layout.Section>
          </Layout>
        </Page>
        {showToast && (
            <Toast
              content={toastMessage}
              onDismiss={() => setShowToast(false)}
            />
          )}
        <Modal
          open={isEditing}
          onClose={() => setIsEditing(false)}
          title="Edit Notification"
          primaryAction={{
            content: 'Save',
            onAction: handleFormSubmit,
          }}
          secondaryActions={[
            {
              content: 'Cancel',
              onAction: () => setIsEditing(false),
            },
          ]}
        >
          <Modal.Section>
            <div style={{ display: 'flex' }}>
              <section style={{ flex: 1, paddingRight: '20px' }}>
                <h2><strong>Notification 1</strong></h2>
                <p style={{ marginTop:'5px' , marginBottom: '50px'}}>
                Wait for {editFormData.selectedTime} minutes after "when a subscriber adds a product to the cart without checking out" to send this notification
                </p>
                <Button primary={!editFormData.isActive} onClick={ () => setEditFormData({ ...editFormData, isActive: !editFormData.isActive })}>
                {editFormData.isActive ? 'Disable' : 'Enable'}
                </Button>
              </section>
              <section style={{ flex: 1}}>
              <TextField
                  label="Title"
                  value={editFormData.title}
                  onChange={(value) => setEditFormData({ ...editFormData, title: value })}
                />
                <TextField
                  label="Description"
                  value={editFormData.description}
                  onChange={(value) => setEditFormData({ ...editFormData, description: value })}
                />
                {editFormData.name === "AbandonedCart" && (
                  <Select
                    label="Select Time"
                    options={[
                      { label: '5 minutes', value: '5' },
                      { label: '10 minutes', value: '10' },
                      { label: '15 minutes', value: '15' },
                      { label: '20 minutes', value: '20' },
                      { label: '30 minutes', value: '30' },
                      { label: '60 minutes', value: '60' },
                      { label: 'Immediately', value: '0' },
                    ]}
                    onChange={(value) => setEditFormData({ ...editFormData, selectedTime: value })}
                    value={editFormData.selectedTime}
                  />
                )}
              </section>
            </div>
          {editFormData.name === "AbandonedCart1" && (
            <><div style={{ display: 'flex', marginTop: '25px' }}>
              <section style={{ flex: 1, paddingRight: '20px' }}>
                <h2><strong>Notification 2</strong></h2>
                <p style={{ marginTop: '5px', marginBottom: '50px' }}>
                  Wait for {editFormData.selectedTime1} minutes after "when a subscriber adds a product to the cart without checking out" to send this notification
                </p>
                <Button primary={!editFormData.isActive1} onClick={() => setEditFormData({ ...editFormData, isActive1: !editFormData.isActive1 })}>
                  {editFormData.isActive1 ? 'Disable' : 'Enable'}
                </Button>
              </section>
              <section style={{ flex: 1 }}>
                <TextField
                  label="Title"
                  value={editFormData.title1}
                  onChange={(value) => setEditFormData({ ...editFormData, title1: value })} />
                <TextField
                  label="Description"
                  value={editFormData.description1}
                  onChange={(value) => setEditFormData({ ...editFormData, description1: value })} />
                <Select
                  label="Select Time"
                  options={[
                    { label: '5 minutes', value: '5' },
                    { label: '10 minutes', value: '10' },
                    { label: '15 minutes', value: '15' },
                    { label: '20 minutes', value: '20' },
                    { label: '30 minutes', value: '30' },
                    { label: '60 minutes', value: '60' },
                    { label: 'Immediately', value: '0' },
                  ]}
                  onChange={(value) => setEditFormData({ ...editFormData, selectedTime1: value })}
                  value={editFormData.selectedTime1} />
                </section>
              </div><div style={{ display: 'flex', marginTop: '25px' }}>
                <section style={{ flex: 1, paddingRight: '20px' }}>
                  <h2><strong>Notification 3</strong></h2>
                  <p style={{ marginTop: '5px', marginBottom: '50px' }}>
                    Wait for {editFormData.selectedTime2} minutes after "when a subscriber adds a product to the cart without checking out" to send this notification
                  </p>
                  <Button primary={!editFormData.isActive2} onClick={() => setEditFormData({ ...editFormData, isActive2: !editFormData.isActive2 })}>
                    {editFormData.isActive2 ? 'Disable' : 'Enable'}
                  </Button>
                </section>
                <section style={{ flex: 1 }}>
                  <TextField
                    label="Title"
                    value={editFormData.title2}
                    onChange={(value) => setEditFormData({ ...editFormData, title2: value })} />
                  <TextField
                    label="Description"
                    value={editFormData.description2}
                    onChange={(value) => setEditFormData({ ...editFormData, description2: value })} />
                  <Select
                    label="Select Time"
                    options={[
                      { label: '5 minutes', value: '5' },
                      { label: '10 minutes', value: '10' },
                      { label: '15 minutes', value: '15' },
                      { label: '20 minutes', value: '20' },
                      { label: '30 minutes', value: '30' },
                      { label: '60 minutes', value: '60' },
                      { label: 'Immediately', value: '0' },
                    ]}
                    onChange={(value) => setEditFormData({ ...editFormData, selectedTime2: value })}
                    value={editFormData.selectedTime2} />
                </section>
              </div></> 
            )}
          </Modal.Section> 
        </Modal>
      </Frame>
    </div>
  );
}