import React from 'react';
import '@shopify/polaris/build/esm/styles.css';
import enTranslations from '@shopify/polaris/locales/en.json';
import { AppProvider, Page, Frame, Tabs } from '@shopify/polaris';
import HomePage from './views/index.jsx';
import PushNotificationSection from './views/integration.jsx';
import PushNotificationCampaign from './views/campaign.jsx';
import InstructionPage from './views/guidelines.jsx';
// import ExitIframe from './views/ExitIframe.jsx';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';

export default function App() {

  const storeDomain = new URL(window.location).searchParams.get("shop");

  return (
    <AppProvider i18n={enTranslations}>
      <Frame>
        <Router>
          <Page title="Elite Push Notification">
            <Tabs
              tabs={[
                {
                  id: 'tab1',
                  content: <Link to={'/configuration?shop=' + storeDomain} style={{ textDecoration: 'none', color: 'black' }}>Configuration</Link>,
                },
                {
                  id: 'tab2',
                  content: <Link to={'/integrations?shop=' + storeDomain} style={{ textDecoration: 'none', color: 'black' }}>Integrations</Link>,
                },
                {
                  id: 'tab3',
                  content: <Link to={'/campaign?shop=' + storeDomain} style={{ textDecoration: 'none', color: 'black' }}>Campaign</Link>,
                },
                {
                  id: 'tab4',
                  content: <Link to={'/guidelines?shop=' + storeDomain} style={{ textDecoration: 'none', color: 'black' }}>Guidelines</Link>,
                },
              ]}
            />
            <Route path="/configuration" component={HomePage} />
            <Route path="/integrations" component={PushNotificationSection} />
            <Route path="/campaign" component={PushNotificationCampaign} />
            <Route path="/guidelines" component={InstructionPage} />
          </Page>
        </Router>
      </Frame>
    </AppProvider>
  );
}