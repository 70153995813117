import React, { useState, useEffect } from 'react';

const starStyles = {
  cursor: 'pointer',
  fontSize: '200px',
};

const Star = ({ marked, starId, onClick, onMouseEnter, onMouseLeave }) => {
  const starColor = marked ? '#ff9933' : '#333';
  const fontSize = marked ? '38px' : '34px';
  return (
    <span
      data-star-id={starId}
      className="star"
      role="button"
      style={{ ...starStyles, fontSize, color: starColor }}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {marked ? '\u2605' : '\u2606'}
    </span>
  );
};

const StarRating = ({ onFeedbackForm , resetStars}) => {
  const [rating, setRating] = useState(0);
  const [selection, setSelection] = useState(0);
  const [feedbackMessage, setFeedbackMessage] = useState('Please Rate Your Experience!');

  const handleRatingClick = async (newRating) => {
    setRating(newRating);

    if (newRating < 5) {
      setFeedbackMessage(getFeedbackMessage(newRating));
      onFeedbackForm(newRating);
      console.log("done");
    } else {
      setFeedbackMessage(getFeedbackMessage(newRating));
      window.open('https://apps.shopify.com/elite-social-login#modal-show=ReviewListingModal', '_blank');
      const contact = {
        star: newRating,
      };
      console.log(contact);
      const response = await fetch("/api/rating_feedback", {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ contact }),
      });

      if (response.ok) {
        console.log(response.status);
      } else {
        console.error('Failed to save settings');
      } 
    }
  };

  const handleStarHover = (newRating) => {
    setFeedbackMessage(getFeedbackMessage(newRating));
  };

  const handleStarLeave = () => {
    setFeedbackMessage('Please Rate Your Experience!');
  };
  const hoverOver = (event) => {
    let val = 0;
    if (event && event.target && event.target.getAttribute('data-star-id')) {
      val = event.target.getAttribute('data-star-id');
    }
    setSelection(val);
  };

  const getFeedbackMessage = (newRating) => {
    switch (newRating) {
      case 1:
        return "Not satisfied with our application? Please let us know what went wrong.";
      case 2:
        return "Exist any problems? If you let us know, we'll correct it right away.";
      case 3:
        return "Please let us know if you require any assistance.";
      case 4:
        return "Please let us know what we can improve with our application.";
      case 5:
        return "We appreciate your support of our efforts.";
      default:
        return "Please Rate your experience!";
    }
  };
  useEffect(() => {
    
    if (resetStars) {
      setRating(0);
    }
  }, [resetStars]);

  return (
    <div onMouseOut={() => hoverOver(null)} onMouseOver={hoverOver}>
      <div style={{ marginBottom: '15px' }}>{feedbackMessage}</div>
      {Array.from({ length: 5 }, (v, i) => (
        <Star
          starId={i + 1}
          key={`star_${i + 1}`}
          marked={selection ? selection >= i + 1 : rating >= i + 1}
          onClick={() => handleRatingClick(i + 1)}
          onMouseEnter={() => handleStarHover(i + 1)}
          onMouseLeave={handleStarLeave}
        />
      ))}
      
    </div>
  );
};

export default StarRating;
