import React, { useState, useEffect, useCallback } from 'react';
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css'
import { Card, Layout, FormLayout, TextField, Button, Page, Frame, Toast, VerticalStack, DropZone, Text, TextContainer, RadioButton, Label } from '@shopify/polaris';
import { NoteMinor } from '@shopify/polaris-icons';
import { googleChrome, safariBrowse } from "../assets";

export default function PushNotificationCampaign() {
  
  const locations = new URL(window.location).searchParams;
  const storeDomain = locations.get("shop");
  const editSection = locations.get("edit");
  const campaignId = parseInt(locations.get("id"), 10);
  const [userId, setUserId] = useState('');
  const [notificationTitle, setNotificationTitle] = useState('');
  const [notificationBody, setNotificationBody] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [imageUrls, setImageUrls] = useState(googleChrome);
  const [notificationType, setNotificationType] = useState('active');
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [scheduledNotifications, setScheduledNotifications] = useState([]);
  const [selectedOption, setSelectedOption] = useState('send');
  const [showFields, setShowFields] = useState(false);
  const [actionButtonTitle, setActionButtonTitle] = useState('');
  const [redirectUrl, setRedirectUrl] = useState('');
  const [editingIndex, setEditingIndex] = useState(-1);
  const [loading, setLoading] = useState(true);
  const [toastMessage, setToastMessage] = useState('');
  const [showToast, setShowToast] = useState(false);

  console.log("campaignId", campaignId, "editSection", editSection);
  const handleDrop = useCallback((acceptedFiles) => {
    setImageUrl(acceptedFiles[0]);
  }, []);

  const removeImage = () => {
    setImageUrl("");
    setImageUrls(googleChrome);
  };

  const handleOptionChange = (value) => {
    setSelectedOption(value);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('/api/get_data?shop=' + storeDomain, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });
        if (response.ok) {
          const data = await response.json();
          if (data) {
            data.notification_table.forEach( async (notificationData, index) => {
              const { notification_payload } = notificationData;
              if (index === campaignId) {
                setEditingIndex(index);
                setUserId(notificationData.id);
                setSelectedOption(editSection);
                setImageUrls(notification_payload.imageUrl);
                setNotificationTitle(notification_payload.notificationTitle);
                setNotificationBody(notification_payload.notificationBody);
                setNotificationType(notification_payload.notificationType);
                setSelectedDate(new Date(notification_payload.selectedDate));
              }
              var newNotification = `${notificationData.id} - ${notification_payload.notificationTitle} - ${notification_payload.notificationBody} - ${notification_payload.notificationType} - ${notification_payload.selectedDate}`;
              if (!scheduledNotifications.includes(newNotification)) {
                setScheduledNotifications((prevNotifications) => [...prevNotifications, newNotification]);
              }
            });
          }
          console.log('Fetch settings successfully');
        } else {
          console.error('Failed to fetch settings');
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    if (loading) {
      fetchData();
    }
  }, [storeDomain, campaignId, editSection, scheduledNotifications, loading]);

  if (loading) {
    return <div>Loading...</div>;
  }

  const handleSubmit = async () => {
    
    if (!notificationTitle || !notificationBody) {
      setToastMessage('Please enter Notification Title and Body.');
      setShowToast(true);
      return;
    }
    
    const formData = new FormData();
    formData.append('image', imageUrl);
    formData.append('imageUrl', imageUrls);
    formData.append('notificationTitle', notificationTitle);
    formData.append('notificationBody', notificationBody);
    formData.append('notificationType', 'active');
    formData.append('selectedOption', selectedOption);
    formData.append('selectedDate', selectedDate.toISOString());
    formData.append('actionButtonTitle', actionButtonTitle);
    formData.append('redirectUrl', redirectUrl);
    formData.append('userId', userId);

    console.log('New data:', formData);
    const response = await fetch('/api/update_data?shop=' + storeDomain, {
      method: 'POST',
      body: formData,
    });

    if (response.ok) {
      setToastMessage('Settings saved successfully.');
    } else {
      setToastMessage('Failed to save settings.');
    }
    setShowToast(true);
  };

  const handleSendNotification = async () => {
    
    const notificationData = {
      imageUrl,
      notificationTitle,
      notificationBody,
      notificationType: "active",
      selectedOption,
      selectedDate,
      actionButtonTitle,
      redirectUrl,
      userId,
    };

    const response = await fetch('/route/allsubscribe?shop='+storeDomain, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(notificationData),
    });

    if (response.ok) {
      setToastMessage('Send Notification Successfully.');
    } else {
      setToastMessage('Failed to Send Notification.');
    }
    setShowToast(true);
  };

  const handleSaveAndSchedule = () => {

    if (editingIndex !== -1) {
      const updatedNotification = `${userId} - ${notificationTitle} - ${notificationBody} - ${notificationType} - ${selectedDate.toISOString()}`;
      const updatedScheduledNotifications = [...scheduledNotifications];
      updatedScheduledNotifications[editingIndex] = updatedNotification;
      setScheduledNotifications(updatedScheduledNotifications);
      setEditingIndex(-1);
    } else {
      const newNotification = `${userId} - ${notificationTitle} - ${notificationBody} - ${notificationType} - ${selectedDate.toISOString()}`;
      setScheduledNotifications([...scheduledNotifications, newNotification]);
    }

    setUserId('');
    setNotificationTitle('');
    setNotificationBody('');
    setNotificationType('active');
    handleSubmit();
  };

  const googleChromeIcon = `
        <svg xmlns="http://www.w3.org/2000/svg" height="1.2em" viewBox="0 0 512 512">
        <path d="M0 256C0 209.4 12.47 165.6 34.27 127.1L144.1 318.3C166 357.5 207.9 384 256 384C270.3 384 283.1 381.7 296.8 377.4L220.5 509.6C95.9 492.3 0 385.3 0 256zM365.1 321.6C377.4 302.4 384 279.1 384 256C384 217.8 367.2 183.5 340.7 160H493.4C505.4 189.6 512 222.1 512 256C512 397.4 397.4 511.1 256 512L365.1 321.6zM477.8 128H256C193.1 128 142.3 172.1 130.5 230.7L54.19 98.47C101 38.53 174 0 256 0C350.8 0 433.5 51.48 477.8 128V128zM168 256C168 207.4 207.4 168 256 168C304.6 168 344 207.4 344 256C344 304.6 304.6 344 256 344C207.4 344 168 304.6 168 256z"
          fill="#8A8A8A"/>
        </svg>`;

  const tabContents = [
    (
      <div
        style={{
          width: '24rem',
          height: '6rem',
          backgroundColor: '#f0f0f0',
          borderRadius: '12px',
          boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1), 10px 10px 15px rgba(0, 0, 0, 0.1), -10px 10px 15px rgba(0, 0, 0, 0.1)',
          display: 'flex',
          alignItems: 'center',
          fontSize: '.75rem',
        }}
      >
        <img
          src={safariBrowse} 
          alt="Safari Browser Icon"
          style={{
            maxWidth: '50px',
            maxHeight: '50px',
            marginLeft: '20px',
          }}
        />
        <div
          style={{
            flex: 1,
            marginLeft: '20px',
          }}
        >
          <h3><b>{notificationTitle || 'Title'}</b></h3>
          <p>from: {storeDomain}</p>
          <p>{notificationBody || 'Message'}</p>
        </div>
        <img
          src={(imageUrl !== '') ? URL.createObjectURL(imageUrl) : imageUrls} 
          alt="Notification Image"
          style={{
            maxWidth: '40px',
            maxHeight: '40px',
            marginRight: '20px',
          }}
        />
      </div>
    ), // Mac OS
    (
      <div
        style={{
          width: '24rem',
          height: '9rem',
          backgroundColor: '#000000',
          borderRadius: '1px',
          boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1), 10px 10px 15px rgba(0, 0, 0, 0.1), -10px 10px 15px rgba(0, 0, 0, 0.1)', 
          alignItems: 'center',
          fontSize: '.75rem',
          position: 'relative',
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center', marginLeft: '10px', padding: '10px' }}>
          <div dangerouslySetInnerHTML={{ __html: googleChromeIcon }}></div>
          <div style={{ color: 'white', marginLeft: '8px' }}>Google Chrome</div>
        </div>

        <div style={{ display: 'flex' }}>
          <img
            src={(imageUrl !== '') ? URL.createObjectURL(imageUrl) : imageUrls}
            alt="Grey Box Image"
            style={{
              maxWidth: '50px',
              maxHeight: '50px',
              marginLeft: '20px',
            }}
          />
          <div
            style={{
              flex: 1,
              marginLeft: '20px',
              color: 'white',
            }}
          >
            <h3><b>{notificationTitle || 'Title'}</b></h3>
            <p>{notificationBody || 'Message'}</p>
            <p>{storeDomain}</p>
          </div>
        </div>
        {actionButtonTitle && (
        <button
          style={{
            position: 'absolute',
            bottom: '10px',
            right: '10px',
            backgroundColor: 'grey',
            color: 'white',
            border: 'none',
            borderRadius: '4px',
            padding: '8px 12px',
            marginTop: '12px',
            cursor: 'pointer',
          }}
        >
          {actionButtonTitle || 'text'}
        </button>
        )}
      </div>
    ), // windows 10, 11
    (
      <div
        style={{
          width: '24rem',
          height: '8rem',
          backgroundColor: '#000000',
          borderRadius: '4px',
          boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1), 10px 10px 15px rgba(0, 0, 0, 0.1), -10px 10px 15px rgba(0, 0, 0, 0.1)', 
          alignItems: 'center',
          fontSize: '.75rem',
          position: 'relative',
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center', marginLeft: '10px', padding: '10px' }}>
          <div dangerouslySetInnerHTML={{ __html: googleChromeIcon }}></div>
          <div style={{ color: 'white', marginLeft: '8px' }}> 
            Chrome 
            <span className="font-semibold"> &nbsp;•&nbsp; </span>
            {storeDomain}
            <span className="font-semibold">&nbsp;•&nbsp; </span> 
            Now
          </div>
        </div>

        <div style={{ display: 'flex' }}>
          <div
            style={{
              flex: 1,
              marginLeft: '20px',
              color: 'white'
            }}
          >
            <h3><b>{notificationTitle || 'Title'}</b></h3>
            <p>{notificationBody || 'Message'}</p>
          </div>
          <img
            src={(imageUrl !== '') ? URL.createObjectURL(imageUrl) : imageUrls}
            alt="Grey Box Image"
            style={{
              maxWidth: '40px',
              maxHeight: '40px',
              marginRight: '20px',
            }}
          />
        </div>
        {actionButtonTitle && (
        <button
          style={{
            position: 'absolute',
            bottom: '10px',
            left: '10px',
            backgroundColor: '#000000',
            color: 'white',
            border: '1px solid white',
            borderRadius: '4px',
            padding: '8px 12px',
            cursor: 'pointer',
          }}
        >
          {actionButtonTitle || "text"}
        </button>
        )}
      </div>
    ), // Android
    (
      <div
        style={{
          width: '24rem',
          height: '7rem',
          backgroundColor: '#f0f0f0',
          borderRadius: '12px',
          boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1), 10px 10px 15px rgba(0, 0, 0, 0.1), -10px 10px 15px rgba(0, 0, 0, 0.1)',
          alignItems: 'center',
          fontSize: '.75rem',
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center', marginLeft: '10px', padding: '10px' }}>
          <div dangerouslySetInnerHTML={{ __html: googleChromeIcon }}></div>
          <div style={{ color: 'black', marginLeft: '8px' }}>Google Chrome</div>
        </div>
        <div style={{ display: 'flex'}}>
        <img
          src={(imageUrl !== '') ? URL.createObjectURL(imageUrl) : imageUrls}
          alt="Grey Box Image"
          style={{
            maxWidth: '50px',
            maxHeight: '50px',
            marginLeft: '20px',
          }}
        />
        <div
          style={{
            flex: 1,
            marginLeft: '20px',
          }}
        >
          <h3><b>{notificationTitle || 'Title'}</b></h3>
          <p>from: Safari</p>
          <p>{notificationBody || 'Message'}</p>
        </div>
        </div>
      </div>
    ), // IPhone/IPad
  ];
  
  return (
    <div style={{ height: '500px' }}>
      <Frame>
        <Page
          title={campaignId ? "Edit Campaign" : "Create new Campaign"}
          primaryAction={{
            content: 'Save & Schedule',
            onAction: handleSaveAndSchedule,
          }}
        >
          <Layout>
            <Layout.Section secondary>
              <VerticalStack spacing="loose" distribution="fill" wrap="false">
                <Card>
                  <FormLayout>
                    <RadioButton
                      label="Send Now"
                      checked={selectedOption === 'send'}
                      id="send-option"
                      onChange={() => handleOptionChange('send')}
                    />
                    <RadioButton
                      label="Scheduled Message"
                      checked={selectedOption === 'schedule'}
                      id="schedule-option"
                      onChange={() => handleOptionChange('schedule')}
                    />
                    {selectedOption === 'schedule' && (
                    <DatePicker
                      showTimeSelect
                      selected={selectedDate}
                      onChange={(date) => setSelectedDate(date)}
                      minDate={new Date()}
                      timeIntervals={15}
                      dateFormat="MMMM d, yyyy h:mm aa"
                    />
                    )}
                    <TextField
                      type='hidden'
                      value={userId}
                      onChange={setUserId}
                      placeholder="Enter Id"
                    />
                    <TextField
                      label="Notification Title"
                      value={notificationTitle}
                      onChange={setNotificationTitle}
                      placeholder="Enter Notification Title"
                    />
                    <TextField
                      label="Notification Body"
                      value={notificationBody}
                      onChange={setNotificationBody}
                      multiline={3}
                      placeholder="Enter Notification Body"
                    />
                    <DropZone onDrop={handleDrop} accept="image/*" allowMultiple={false}>
                      {imageUrl || imageUrls ? (
                        <div style={{ position: 'relative', width: '100%', height: '100%' }}>
                          <img
                            src={(imageUrl !== '') ? URL.createObjectURL(imageUrl) : imageUrls}
                            alt="Uploaded"
                            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                          />
                          <button onClick={removeImage} style={{ position: 'absolute', top: '8px', right: '8px' }}>
                            Remove Image
                          </button>
                        </div>
                      ) : (
                        <div>
                          <NoteMinor />
                          <Text>Drag and drop image</Text>
                        </div>
                      )}
                    </DropZone>
                    <Button onClick={() => setShowFields(!showFields)} >
                      {showFields ? 'Hide Action Button' : 'Add Action Button'}
                    </Button>
                    {showFields && (
                      <>
                        <TextField
                          label="Title"
                          value={actionButtonTitle}
                          onChange={setActionButtonTitle}
                          placeholder="Enter Title"
                        />
                        <TextField
                          label="Redirect URL"
                          value={redirectUrl}
                          onChange={setRedirectUrl}
                          placeholder="Enter Redirect URL"
                        />
                      </>
                    )}
                    <VerticalStack>
                    <Button primary onClick={handleSendNotification}>
                      Send Notification
                    </Button>
                    </VerticalStack>
                  </FormLayout>
                </Card>
              </VerticalStack>
            </Layout.Section>
            <Layout.Section secondary>
              <VerticalStack spacing="loose" distribution="fill" wrap="false">
                  <Card>
                    <VerticalStack spacing="tight">
                      <TextContainer>
                        <Label><b>Preview Notification</b></Label>
                        <div>Mac OS</div>
                        <div>{tabContents['0']}</div>
                        <div>Windows 10, 11</div>
                        <div>{tabContents['1']}</div>
                        <div>Android</div>
                        <div>{tabContents['2']}</div>
                        <div>IPhone/IPad</div>
                        <div>{tabContents['3']}</div>
                      </TextContainer>
                    </VerticalStack>
                  </Card>
                </VerticalStack>
            </Layout.Section>
          </Layout>
          {showToast && (
            <Toast
              content={toastMessage}
              onDismiss={() => setShowToast(false)}
            />
          )}
        </Page>
        <Page></Page>
        <Page></Page>
        <Page></Page>
      </Frame>
    </div>
  );
};

