import { Page, Layout, Card, List, Image, VerticalStack } from '@shopify/polaris';
import { allowNotificationOnStore, campaignDashboard, createNewCampaign, enableAppOnStore, enablePushNotificationStore, hiNotificationStore } from "../assets";

export default function InstructionPage() {
  
  return (
    <Page title="User Guidelines">
      <Layout>
        <Layout.Section>
          <Card>
              <p style={{ marginBottom: '10px', fontWeight: 'bold' }}>Instructions to set up web push notification:</p>
              <List type="bullet">
                <VerticalStack>
                    <List.Item>
                          <div style={{ marginBottom: '10px' }}>
                            Go to the Elite Push Notification app and you will be navigate to <b>Campaign Dashboard</b>.
                          </div>
                          <div style={{ width: '830px', height: '300px' }}>
                            <Image source={campaignDashboard} alt="campaign-dashboard" style={{ width: '75%', height: '100%' }} />
                          </div><p>.</p>
                    </List.Item>
                <VerticalStack>
                    <List.Item>
                          <div style={{ marginBottom: '10px' }}>
                            Click on the <b>Enable</b> button to enable the app on your store.
                          </div>
                          <div style={{ width: '830px', height: '300px' }}>
                            <Image source={enableAppOnStore} alt="enable-app-on-store" style={{ width: '75%', height: '100%' }} />
                          </div><p>.</p>
                    </List.Item>
                  </VerticalStack>
                  <VerticalStack>
                    <List.Item>
                          <div style={{ marginBottom: '10px' }}>
                            You will be redirected to the configuration <b>Customize Store Embed</b>. Click on the Toggle button to enable the <b>Elite Push Notification</b> on your store.
                          </div>
                          <div style={{ width: '830px', height: '450px' }}>
                            <Image source={enablePushNotificationStore} alt="enable-push-notification" style={{ width: '75%', height: '75%' }} />
                          </div>
                    </List.Item>
                  </VerticalStack>
                  <VerticalStack>
                    <List.Item>
                          <div style={{ marginBottom: '10px' }}>
                            Navigate to shopify store frontend and you will see the <b>Allow Notification</b> on right corner of the browser.
                          </div>
                          <div style={{ marginBottom: '18px', marginRight: '30px', width: '830px', height: '450px' }}>
                            <Image source={allowNotificationOnStore} alt="allow-notification" style={{ width: '75%', height: '75%' }} />
                          </div>
                    </List.Item>
                  </VerticalStack>
                  <VerticalStack>
                    <List.Item>
                          <div style={{ marginBottom: '10px' }}>
                            Click on the <b>Create New Campaign</b> button. Select Send Now option fill in the details like Notification Title, Description and Upload Image click on the <b>Save</b> button.
                        </div>
                          <div style={{ marginBottom: '18px', marginRight: '30px', width: '830px', height: '300px' }}>
                            <Image source={createNewCampaign} alt="create-new-campaign" style={{ width: '75%', height: '100%' }} />
                          </div><p>.</p>
                    </List.Item>
                  </VerticalStack>
                  <VerticalStack>
                    <List.Item>
                          <div style={{ marginBottom: '10px' }}>
                            You will be able to see the notification on your store frontend. As shown in the below image.
                          </div>
                          <div style={{ marginBottom: '18px', marginRight: '30px', width: '830px', height: '300px' }}>
                            <Image source={hiNotificationStore} alt="hi-notification" style={{ width: '75%', height: '100%' }} />
                          </div><p>.</p>
                    </List.Item>
                  </VerticalStack>
                </VerticalStack>
              </List>
          </Card>
        </Layout.Section>
      </Layout>
    </Page>
  );
}